@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  border-radius: 5px;
  border-width: 1px;
  border-color: #0000005f;
}

.p-treeselect-label-container {
  display: flex;
  align-content: center;
}

.p-checkbox .p-checkbox-input {
  border: 2px solid #d1d5db;
  border-radius: 6px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #d1d5db;
  background: #ffffff;
  width: 22px;
  height: 22px;
  color: #4b5563;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  outline-color: transparent;
}
